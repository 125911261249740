(function($) {
    "use strict"; // Start of use strict


    // Offset for Main Navigation
    $('#mainNav').affix({
        offset: {
            top: 100
        }
    })



    /* Angebot Anfrage Validation
    ================================================== */

    /* $("#contact-form-anfrage").validate({
        ignore: [],
        rules: {
            anrede: {
                required: true,
                minlength: 1
            },
            forename: {
                required: true,
                minlength: 2
            },
            name: {
                required: true,
                minlength: 2
            },
            email: {
                required: true,
                email: true
            },
            company: {
                required: true,
                minlength: 1
            }
        },
        messages: {
            anrede: {
                required: "Bitte wählen Sie Ihre Anrede aus."
            },
            forename: {
                required: "Bitte geben Sie Ihren Vornamen ein.",
                minlength: "Ihr Vorname muss mindestens aus zwei Zeichen bestehen."
            },
            name: {
                required: "Bitte geben Sie Ihren Nachnamen ein.",
                minlength: "Ihr Nachname muss mindestens aus zwei Zeichen bestehen."
            },
            email: "Bitte geben Sie Ihre E-Mail-Adresse ein.",
            company: {
                required: "Bitte geben Sie Ihr Unternehmen ein."
            }
        },
        onfocusout: false,
        invalidHandler: function(form, validator) {
            var errors = validator.numberOfInvalids();
            if (errors) {
                validator.errorList[0].element.focus();
            }
        },

        submitHandler: function(form) {
            $(form).ajaxSubmit({
                target: '#contact-form-result-success',
                type: "POST",
                data: $(form).serialize(),
                url: "form/send.php",
                success: function() {
                    $("#contact-form-anfrage").fadeOut(200, function() {
                        $('.formHeaderAngebot').fadeOut(100);
                        $('#contact-form-result-success').fadeIn(1500);
                        $('#contact-form-result-success').append('<p class="nl-small">Vielen Dank für Ihr Interesse an unserem Trendmonitor!</p><p class="nl-small">Wir werden Ihnen umgehend eine Antwort zukommen lassen.</p>');
                    });
                    ga('send', {
                        hitType: 'event',
                        eventCategory: 'Forms',
                        eventAction: 'send',
                        eventLabel: 'Lead'
                    });
                },
                error: function() {
                    $('#contact-form-result-error').fadeIn(1500);
                }
            });
        }
    }); */
    /*
     * Translated default messages for the jQuery validation plugin.
     * Locale: DE (German, Deutsch)
     */
    // $.extend($.validator.messages, {
    //     required: "Dieses Feld ist ein Pflichtfeld.",
    //     maxlength: $.validator.format("Geben Sie bitte maximal {0} Zeichen ein."),
    //     minlength: $.validator.format("Geben Sie bitte mindestens {0} Zeichen ein."),
    //     rangelength: $.validator.format("Geben Sie bitte mindestens {0} und maximal {1} Zeichen ein."),
    //     email: "Geben Sie bitte eine gültige E-Mail Adresse ein.",
    //     url: "Geben Sie bitte eine gültige URL ein.",
    //     date: "Bitte geben Sie ein gültiges Datum ein.",
    //     number: "Geben Sie bitte eine Nummer ein.",
    //     digits: "Geben Sie bitte nur Ziffern ein.",
    //     equalTo: "Bitte denselben Wert wiederholen.",
    //     range: $.validator.format("Geben Sie bitte einen Wert zwischen {0} und {1} ein."),
    //     max: $.validator.format("Geben Sie bitte einen Wert kleiner oder gleich {0} ein."),
    //     min: $.validator.format("Geben Sie bitte einen Wert größer oder gleich {0} ein."),
    //     creditcard: "Geben Sie bitte eine gültige Kreditkarten-Nummer ein."
    // });


    /* EU-COOKIE
    ================================================================================ */
    var eleu = jQuery("#trurnit-eucookie");
    var buttoneu = jQuery("#trurnit-eucookie button");
    if (-1 === document.cookie.indexOf("trurnit-eucookie")) {
        setTimeout(function() { eleu.slideDown("slow"); }, 1000);
    }
    buttoneu.on("click", function() {
        var date = new Date;
        date.setDate(date.getDate() + 365), document.cookie = "trurnit-eucookie=" + escape("hide") + ";path=/;expires=" + date.toGMTString();
        eleu.slideUp("slow");
    });


    /* Parallax Effect
    ================================================================================ */
    $(window).on('load scroll', function() {
        var scrolled = $(this).scrollTop();
        $('.hero-img.parallax').css("background-position", "50% " + (scrolled / 2) + "px")
        $('.intro-text.fadeOut').css({
            'transform': 'translate3d(0, ' + -(scrolled * 0.2) + 'px, 0)', // parallax (20% scroll rate)
            //'opacity': 1 - scrolled / 300 // fade out at 400px from top
        });
        $('.scroll-down.fadeOut').css({
            'transform': 'translate3d(0, ' + -(scrolled * 0.2) + 'px, 0)', // parallax (20% scroll rate)
            'opacity': 1 - scrolled / 300 // fade out at 400px from top
        });
    });


    /*  ACCORDION
    ================================================================================ */

    var CLASS_ACTIVE = 'active'
    var CLASS_OPEN = 'open'
    var $accordion = $('.js-accordion')

    $accordion.on('click', '.js-toggle', function(e) {
        var $item = $(e.currentTarget).parent()
        var $content = $item.find('.js-content')
        var isOpen = $item.hasClass(CLASS_OPEN)

        if (isOpen) {
            // close:
            $item.removeClass(CLASS_ACTIVE)
            $content.slideUp(function() {
                $item.removeClass(CLASS_OPEN)
                $content.show()
            })
        } else {
            // open:
            $item.addClass(CLASS_ACTIVE + ' ' + CLASS_OPEN)
            $content.hide().slideDown()
        }
    });

    /* Magnific Popup
    ================================================================================ */
    $('.inline-popups').magnificPopup({
        delegate: 'a',
        removalDelay: 500, //delay removal by X to allow out-animation
        callbacks: {
            beforeOpen: function() {
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
    });


    /* Replace all SVG images with inline SVG
    ================================================================================ */
    jQuery('img.svg').each(function() {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });

    /* Move background perspective on mouse move effect
    ================================================== */

    var lFollowX = 0,
        lFollowY = 0,
        x = 0,
        y = 0,
        friction = 1 / 30;

    function moveBackground() {
        x += (lFollowX - x) * friction;
        y += (lFollowY - y) * friction;

        var translate = 'translate(' + x + 'px, ' + y + 'px) scale(1.1)';

        $('.hero-bg').css({
            '-webit-transform': translate,
            '-moz-transform': translate,
            'transform': translate
        });

        window.requestAnimationFrame(moveBackground);
    }

    $(window).on('mousemove click', function(e) {

        var lMouseX = Math.max(-100, Math.min(100, $(window).width() / 2 - e.clientX));
        var lMouseY = Math.max(-100, Math.min(100, $(window).height() / 2 - e.clientY));
        lFollowX = (20 * lMouseX) / 100; // 100 : 12 = lMouxeX : lFollow
        lFollowY = (10 * lMouseY) / 100;

    });

    moveBackground();

    /* Match height
    ================================================== */
    // $(".teaser-section").matchHeight({
    //     byRow: true,
    //     property: 'height',
    //     target: null,
    //     remove: false
    // });

    /* colio plugin
    ================================================== */
    var colio_run = function() {
        $('#trendmonitor-module .wrap').colio({
            id: 'card-expand',
            theme: 'white',
            placement: 'after',
            expandLink: '.card-expand-link',
            scrollOffset: 250,
            syncScroll: true
        });
    };

    colio_run();


})(jQuery); // End of use strict