$(document).ready(function() {
    var controller = new ScrollMagic.Controller();
    var sceneBG1 = new ScrollMagic.Scene({
            triggerElement: "#trendmonitor-leistungen",
            duration: "100%",
            offset: 10,
            reverse: true
        })
        .setClassToggle(".bg-shape", "sm-fade-in")
        .setPin(".bg-shape")
        .addTo(controller);

    var sceneBG2 = new ScrollMagic.Scene({
            triggerElement: "#trendmonitor-vorteile",
            duration: "100%",
            offset: 10,
            reverse: true
        })
        .setClassToggle(".bg-shape-2", "sm-fade-in")
        .setPin(".bg-shape-2")
        .addTo(controller);

    var sceneBG3 = new ScrollMagic.Scene({
            triggerElement: "#trendmonitor-module",
            duration: "100%",
            offset: 300,
            reverse: true
        })
        .setClassToggle(".bg-shape", "sm-fade-in")
        .setPin(".bg-shape")
        .addTo(controller);

    var sceneBG4 = new ScrollMagic.Scene({
            triggerElement: "#trendmonitor-angebot",
            triggerHook: 0,
            reverse: true
        })
        .setClassToggle(".bg-shape", "sm-hide")
        .addTo(controller);

    var sceneBG5 = new ScrollMagic.Scene({
            triggerElement: "#trendmonitor-angebot",
            triggerHook: 0,
            reverse: true
        })
        .setClassToggle(".bg-shape-2", "sm-hide")
        .addTo(controller);

    var sceneCTA = new ScrollMagic.Scene({
            triggerElement: "#cta",
            duration: "50%",
            reverse: true
        })
        .setPin("#cta div")
        .addTo(controller);

    var sceneCTA2 = new ScrollMagic.Scene({
            triggerElement: "#cta",
            duration: "100%",
            reverse: true
        })
        .setClassToggle(".sm-cta-svg", "sm-fade-in")
        .addTo(controller);

    var scene3 = new ScrollMagic.Scene({
            triggerElement: ".sticky-container-1",
            duration: $(".sticky-content").height() - 400,
            triggerHook: 0.3,
            reverse: true
        })
        .setPin(".sticky-image")
        .addTo(controller);


    $('.section').each(function() {

        //console.log(this);
        var controller2 = new ScrollMagic.Controller();

        var sceneSections = new ScrollMagic.Scene({
                triggerElement: this.children[0],
                trigger: 1
            })
            .setClassToggle(this, "sm-show")
            .addTo(controller2);
    });
});